import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  ChangeDetectorRef,
  ViewChild,
} from "@angular/core";
import { environment } from "src/environments/environment";
import { LocationsHttpService } from "../../services/locations-http.service";
import { Location } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService, ToastType } from "src/app/shared/service/toast.service";
import { CircuitsServicesService } from "../../services/circuits-services.service";
import { TranslationService } from "src/app/shared/service/translation.service";
import { CustomTranslationPipe } from "src/app/shared/pipes/custom-translation.pipe";
import { RandomNumberGeneratorService } from "src/app/shared/service/random-number-generator.service";
import { LanguageService } from "src/app/shared/service/language.service";
import { ETOUR_CREATION_STEPS } from "../../interfaces/business-tour-create.interface";
import { MediaCarouselService } from "../../services/media/media-carousel.service";
import { CircuitListService } from "../../services/circuit-list/circuit-list.service";
import { CircuitDetailsResponse, CircuitInfoListEntity, EALL_TRACKS, ESEARCH_PAGE_STRING, EmotionTourListEntity, Layout, MapViewResponse, OffRoadTourListEntity } from "../../interfaces/search-location.interface";
import { TrackDetailModalComponent } from "../../components/track-detail-modal/track-detail-modal.component";
import { Subscription, finalize, of, switchMap } from "rxjs";
import { LOCATIONS_CONSTANTS } from "../../interfaces/locations-constants";
import { AppConstants } from "src/app/shared/constants/constants";
import { StoreManagerService } from "src/app/shared/store-manager/services/store-manager.service";
declare let require: any;
let polyline = require("google-polyline");
@Component({
  selector: "app-circuit-info",
  templateUrl: "./circuit-info.component.html",
  styleUrls: ["./circuit-info.component.scss"],
})
export class CircuitInfoComponent implements OnInit {
  circuitList: any;
  emotionTourList: any;
  limitForEmotionTours: number = 250;
  limitForCircuits: number = 100;
  mapradius: any = LOCATIONS_CONSTANTS.MAP_RADIUS;
  currentCoordinates: string = "48,9";
  toggleValue = "map";
  showAutoList = 0;
  circuitInfo: any;
  apiKey = environment.apikey;
  selectedType: string = EALL_TRACKS.ALL;
  subscribeType: any;
  copyCicuitInfo: any = [];
  copyEmotionTour: any = [];
  emotionTourInfo: any;
  locationData: any;
  showMapView: any = false;
  isShowCircuitInfo: any = false;
  isShowTrackInfo: any = false;
  isShowEmotionTourInfo: any = false;
  emotionTourDetails: any;
  searchLocation: any = false;
  emotionTourTrackencodedGpsPoints: any;
  backToLocationsFromCircuitDetails: any = false;
  isshowDropDown: any = true;
  circleCoordinates: any;
  public navigateTo: any = "";
  isCircuitDetailsPage: any = false;
  public offRoadTourList: OffRoadTourListEntity[] = [];
  passedList: any[] = [];
  completeList: any[] = [];
  public enableLeftPanelView: boolean = false
  @Output() public isShowMapView = new EventEmitter<object>();

  circuitData: any;
  trackdetails: any;
  layoutstext: any;
  slides: any;
  sectorPointsDetails: any;
  circuitRadius: any;
  currentDate: string = "";
  selectedTrack: any;
  trackLength = 0;
  updatedRadius: any = false;
  previousType: any = EALL_TRACKS.ALL;
  paramGuid: any;
  circuitGuid: any;
  circuitDetails: any;
  showCircuit: any = false;
  showEarthGif: any = null;
  showEarth: any;
  toastMessage = "";
  toastType: any;
  showToast = false;
  position: any;
  leftPosition: any;
  deletedCircuitGuid: any;
  notificationMessage: any;
  public copyDeletedCircuit: any;
  isShowAddCircuitSearch: any = false;
  primaryButtonTitle: any = LOCATIONS_CONSTANTS.BUTTON_NAMES.NEXT;
  secondaryButtonTitle: any = AppConstants.COMMON_BUTTON_LABELS.CANCEL;
  trackDetailsName: any = "SELECT LOCATION";
  primaryButtonColor: any = "primary";
  trackName: any;
  circuitName: any;
  optionToBePassed: any;
  selectedButton: any = "";
  isShowCircuitGeofence: any = false;
  isDisablePrimaryButton: any = true;
  isShowZoombutton: any = false;
  setZoomInLocation: any = {};
  zoomCount: any = 0;
  public mapHeight = "100%";
  mapValues: any;
  locations: any;
  statusTitle: any;
  statusMessage: any;
  statusSubMessage: any;
  statusImage: any;
  isAddCircuit: any = false;
  alertTitle: any = LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.ALERT;
  alertMessage: any = LOCATIONS_CONSTANTS.HELPER_MSGS.RESET_GEOFENCE_MSG;
  alertCancelBtnTitle: any = AppConstants.COMMON_BUTTON_LABELS.CANCEL;
  alertActionBtnTitle: any = AppConstants.COMMON_BUTTON_LABELS.CONFIRM;
  isNavigateBack: any = false;
  type: any;
  showNotFoundPage: any;
  newSelection: any;
  isDropdownOpen: any;
  searchHeader: any = AppConstants.COMMON_TITLE_LABELS.LOCATION_TITLE;
  searchDescription: any = "BP_LM_landingPage_locationsSearchText";
  searchPlaceholderText: any = "BP_LM_SEARCH_LBL";
  isModalOpen: any = false;
  actionType: any;
  showSearchAndCreate:any;
  selectedAnnotationsId: any;
  page:any;
  selectedLocationAddress:any;
  selectedTrackFromSearch:any;
  enableRadiusInputBox: any;
  radiusValue: any;
  radiusValueFromTool: any;
  clearDrawnItems: any;
  showModal:any;
  selectedUserType:any = EALL_TRACKS.ALL;
  yourTracks: any;
  searchResults: any;
  mapCircuitsList: any;
  mapEmotionTourList: any;
  translationData: any;
  phrasePipe: any;
  enableFeature = environment.enableFeature;
  language: string = ''
  subscriptions: Subscription[] = [];
  @ViewChild('trackDetailsComponent') trackDetailsComponent: TrackDetailModalComponent | undefined;
  displayClusterLoader: boolean = false;
  totalNumberOfTours: any;
  searchSubscriprion: Subscription = new Subscription;
  private _enableEmotionTourFeature: boolean = true;
  public enableCreatedByYouFilter: boolean = false;
  private _enableCircuitFeature: boolean = false;

  constructor(
    private locationsHttpService: LocationsHttpService,
    private location: Location,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private circuitService: CircuitsServicesService,
    private changeDetectorRef: ChangeDetectorRef,
    private translationService: TranslationService,
    private randomNumberService: RandomNumberGeneratorService,
    private languageService: LanguageService,
    private _mediaCarousalService: MediaCarouselService,
    private _circuitListService: CircuitListService,
  private _storeManagerService: StoreManagerService  ) {
    this.currentCoordinates = this._storeManagerService.getCurrentMapDataFromStore().latLng;
  }

  @HostListener("window:popstate", ["$event"])
  onBrowserBackBtnClose(_event: Event) {
    localStorage.setItem("isBackButtonClicked", "true");
    this.router.navigateByUrl("/locations");
  }

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    _event: KeyboardEvent) {
    this.selectedButton = 'clear'+ this.randomNumberService.randomNumber();
  }

  async removeIndexedDb(){
    const dbs = await window.indexedDB.databases()
    dbs.forEach((db:any) => { window.indexedDB.deleteDatabase(db.name) })
  }

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.selectedType = EALL_TRACKS.ALL;
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        const locationFeatureFlagObj = this.translationService.featureFlags.locations;
        this.translationData = translationData;
        this.enableCreatedByYouFilter = locationFeatureFlagObj.enableCreatedByYouFilter;
        this._enableEmotionTourFeature = locationFeatureFlagObj.enableEmotionTourFeature;
        this.enableLeftPanelView = locationFeatureFlagObj.enableLeftPanelView;
        this._enableCircuitFeature = locationFeatureFlagObj.enableCircuitFeature;
      }
    );
    this.phrasePipe = new CustomTranslationPipe;    
    localStorage.removeItem("form-data");
    localStorage.removeItem("form-image");
    this.showSearchAndCreate = false;
    this.toastService.toastDetails.subscribe((status: any) => {
      this.showToast = status.isShown;
      this.toastMessage = status.message;
      this.toastType = status.toastType;
      this.position = status.position;
      this.leftPosition = status.leftPosition;
    });
    this.subscriptions.push(this._circuitListService.showTrackDetails.subscribe((track: {show: boolean, layout: Layout}) => {
      this.isShowTrackInfo = track.show;
    }));
    if (this.route.snapshot.routeConfig?.path == "circuit/:guid") {
      this.route.paramMap.subscribe((paramMap: any) => {
        this.locationsHttpService.getCircuit(paramMap.params.guid).subscribe({
          next: (data: any) => {
            let item = data.body
            this.locationData = item;
            this.locationData.guid = paramMap.params.guid;
            this.circuitGuid = paramMap.params.guid;
            this.currentDate = new Date().toLocaleDateString("de-DE", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });

            this.circleCoordinates = item.location;
            if (item.area) {
              this.circuitRadius = item.area * 1000;
            } else {
              this.circuitRadius = polyline.decode(item.encodedPolyline);
            }
            if (item.layouts) {
              this.layoutstext =
                "Layouts" + " " + "(" + this.locationData.layouts.length + ")";
              this.locationData.layouts = this.locationData.layouts.sort(
                (current: any, next: any) => {
                  return (
                    +new Date(next.updatedDate) - +new Date(current.updatedDate)
                  );
                }
              );
            }
            this.updateSlides();
            this.isShowCircuitInfo = true;
            this.searchLocation = false;
            this.isShowEmotionTourInfo = false;
            this.previousType = this.selectedType;
          },
          error: (error: any) => {
            console.log("error", error);
            if (error.status === 406) {
              this.showNotFoundPage = true;
            }
            // this.router.navigateByUrl("/locations/not-found")
          },
        });
      });
      this.subscribeType =
        this.locationsHttpService.shareSelectedType.subscribe((data: any) => {
          this.selectedType = data;
          this.previousType = this.selectedType;
        });
    } else {

      this.subscribeType =
        this.locationsHttpService.shareSelectedType.subscribe((data: any) => {
          this.selectedType = data;
          this.previousType = this.selectedType;
        });
    }
  }

  public updatedCircuitData(event: CircuitDetailsResponse) {
    this.locationData = event;
    if (this.locationData.layouts) {
      this.layoutstext =
        "Layouts" + " " + "(" + this.locationData.layouts.length + ")";
      this.locationData.layouts = this.locationData.layouts.sort(
        (current: any, next: any) => {
          return (
            +new Date(next.updatedDate) - +new Date(current.updatedDate)
          );
        }
      );
    } 
    this.updateSlides();
  }

  updateTrackVisibility(event: any) {
    const currentTrack = this.slides.find((slide: any) => slide.guid === event.guid);
    if (currentTrack) {
      currentTrack.visibility = event.visibility
    }
  }


  /* Commenting code for now, likely to be impleneted in future
  formatYourCircuitData() {
    this.yourTracks.circuitInfoList=this.yourTracks.circuitInfoList?.map((element:any) => {
      element.type = 'circuit';
      element.totalNumberOfLayouts = element.layouts;
      
      const imageDataUrl = element.media?.image[0].originalUrl;
        if(imageDataUrl) {
          this.downloadBlobImage(imageDataUrl, element.guid);
        } else {
          element.imageUrl = "assets/locations/redcircuit.png";
        }         
        return element
    });
    
    console.log('imageDataUrl', this.yourTracks.circuitInfoList)
    this.yourTracks.emotionTourList?.forEach((element:any) => {
      element.type = 'emotion_tour'
    });

  }*/

  /**
   * @description where is this called???
   * Get rid of this code???
   * @param url 
   * @param guid 
   */
  downloadBlobImage(url: any, guid:any) {
    let downloadedImage:any = []
    this.locationsHttpService.getBlobImage(url).subscribe({
      next: (blob: any) => {
        // Convert blob to base64

        const reader = new FileReader();

        reader.readAsDataURL(blob);

        reader.onloadend = () => {
          let imageData = reader.result?.toString() ?? "assets/locations/redcircuit.png"
          this.yourTracks.circuitInfoList.forEach((element: any) => {
            if(element.guid === guid) {
              element.imageUrl = imageData
            }
          });
          
        };
      }
    });

  }

  getMapData(
    emotionTourLimit: any,
    circuitLimit: any,
    offroadLimit: number,
    point: any,
    radius: any
  ) {
    const coordinates = point.includes('undefined') ? this.currentCoordinates : point;
    radius = !radius ? this.mapradius : radius;
    circuitLimit = this._enableCircuitFeature ? circuitLimit : 0;
    emotionTourLimit = this._enableEmotionTourFeature ? emotionTourLimit : 0;
    this.searchSubscriprion.unsubscribe();
    this.searchSubscriprion = this.locationsHttpService
      .getAllMapViewData(emotionTourLimit, circuitLimit, offroadLimit, coordinates, radius)
      .pipe(switchMap((data: MapViewResponse) => {
       return of(data);
      }))
      .pipe(finalize(() => {
        this.searchSubscriprion.unsubscribe();
        this.displayClusterLoader = false
        this.currentCoordinates = point;
        this.mapradius = radius;
      }))  
      .subscribe({
        next: (res: any) => {
          let data: MapViewResponse = res.body
          this.showCircuit = true;
          if (!sessionStorage.getItem("shown")) {
            setTimeout(() => {
              this.showEarthGif = false;
              sessionStorage.setItem("shown", "true");
            }, 6000);
            setTimeout(() => {
              this.showCircuit = true;
            }, 5000);
          }
          this.handleMapViewResp(data);
            this.passedList = [];
            this.passedList = this.circuitService.handleSearchApiResponse(data);
        },
        error: (error: any) => {
          console.log(error);
        },
      });
  }

  public handleMapViewResp(data: MapViewResponse): void {
    if (data.circuitInfoList && data.emotionTourList) {        // Add type
        this.passedList = [] 
        this.circuitList = this.mapCircuitsList = this.emotionTourList = this.offRoadTourList = [];
      this.circuitList = data.circuitInfoList;
      this.circuitList.viewType = EALL_TRACKS.CIRCUIT;
      this.mapCircuitsList = this.circuitList;
      this.emotionTourList = data.emotionTourList;
      this.offRoadTourList = data.offRoadTourList
      this.emotionTourList.viewType = EALL_TRACKS.EMOTION_TOUR;
      data.emotionTourList = this._enableEmotionTourFeature ? data.emotionTourList : [];
      this.mapEmotionTourList = this.emotionTourList;
      // Why do we have so many duplicate variables? Shreya to check and clean-up
      this.copyCicuitInfo = this.copyCicuitInfo.concat(
        data.circuitInfoList
      );
      this.copyEmotionTour = this.copyEmotionTour.concat(
        data.emotionTourList
      );
      if (this.copyDeletedCircuit) {
        this.deletedCircuitGuid = this.copyDeletedCircuit;
        this.copyDeletedCircuit = null;
        this.isShowCircuitInfo = false;
        this.isShowEmotionTourInfo = false;
        this.backToLocationsFromCircuitDetails = false;
        this.searchLocation = false;
        this.isShowTrackInfo = false;
      }
    } else if (data.emotionTourList.length) {
      if (this.copyEmotionTour.length) {
        data.emotionTourList = data.emotionTourList?.filter(
          (entry1: any) =>
            !this.copyEmotionTour.some(
              (entry2: any) => entry1.guid === entry2.guid
            )
        );
      }
      this.emotionTourList = data.emotionTourList;
      this.emotionTourList.viewType = EALL_TRACKS.EMOTION_TOUR;
      this.circuitList = [];
      this.copyEmotionTour = this.copyEmotionTour.concat(
        data.emotionTourList
      );
    } else if (data.circuitInfoList.length) {
      if (this.copyCicuitInfo.length) {
        data.circuitInfoList = data.circuitInfoList.filter(
          (entry1: any) =>
            !this.copyCicuitInfo.some(
              (entry2: any) => entry1.guid === entry2.guid
            )
        );
      }
      this.circuitList = data.circuitInfoList;
      this.circuitList.viewType = EALL_TRACKS.CIRCUIT;
      this.emotionTourList = [];
      this.copyCicuitInfo = this.copyCicuitInfo.concat(
        data.circuitInfoList
      );
    } else {
      this.emotionTourList = [];
      this.circuitList = [];
    }
  }

  /**
   * @returns void
   * @param Object - latitude and longitude, radius
   * @description called when a pin is clicked map or map is resized, default selectedType is all.
   * selectedType can be changed to emotion tour or circuit via tabs
   */
  getpannedCircuitData(event: any) {
    /* Commenting code for now
    if(this.selectedUserType === 'you') {
      if (event.guid && event.type) {
        let param;
        this.selectedTrackFromSearch=null;
        if (event.isSearch) {
          this.searchLocation = event;
        }
        if(event.selectedTrack){
          this.selectedTrackFromSearch=event.selectedTrack;
      
        }
      
        if (
          event.type.toLowerCase() == EALL_TRACKS.CIRCUIT ||
          event.type.toLowerCase() == EALL_TRACKS.CIRCUIT
        ) {
          param = EALL_TRACKS.CIRCUIT;
          this.circuitGuid = event.guid;
        } else if (
          event.type.toLowerCase() == EALL_TRACKS.EMOTION_TOUR ||
          event.type.toLowerCase() == "emotion_tour"
        ) {
          param = EALL_TRACKS.EMOTION_TOUR;
        }
  
        this.location.replaceState(`/locations/${param}/${event.guid}`);
        this.searchResults = null;
      }
    } else { */
      if (event.guid && event.type) {
        let param;
        this.selectedTrackFromSearch=null;
        if (event.isSearch) {
          this.searchLocation = event;
        }
        if(event.selectedTrack){
          this.selectedTrackFromSearch=event.selectedTrack;
      
        }
      
        if (
          event.type == EALL_TRACKS.CIRCUIT
        ) {
          param = EALL_TRACKS.CIRCUIT.toLowerCase();
          this.circuitGuid = event.guid;
        } else if (
          event.type == EALL_TRACKS.EMOTION_TOUR) {
          param = "business-tour";
        } else if (event.type == EALL_TRACKS.OFF_ROAD || event.type === EALL_TRACKS.OFFROAD_TRACK) {
          param = "offroad-trail";
        }
        if ("business-tour" === param || "offroad-trail" === param) {
          this.router.navigateByUrl(`/locations/${param}/${event.guid}`);
        } else {
          this.location.replaceState(`/${this.language}/locations/${param}/${event.guid}`);
        }
      } else if (this.selectedType.toUpperCase() == EALL_TRACKS.ALL) {
        this.getMapData(
          this.limitForEmotionTours,
          this.limitForCircuits,
          this.limitForCircuits,
          event.lat + "," + event.lng,
          event.radius
        );
      } else if (this.selectedType == EALL_TRACKS.CIRCUIT) {
        this.getMapData(
          0,
          this.limitForCircuits,
          0,
          event.lat + "," + event.lng,
          event.radius
        );
      } else if (this.selectedType == EALL_TRACKS.EMOTION_TOUR) {
        this.getMapData(
          this.limitForEmotionTours,
          0,
          0,
          event.lat + "," + event.lng,
          event.radius
        );
        this.previousType = this.selectedType;
      } else if(this.selectedType === EALL_TRACKS.OFF_ROAD) {
        this.getMapData(
          0,
          0,
          this.limitForCircuits,
          event.lat + "," + event.lng,
          event.radius
        );
      }
      this.searchResults = null;
    //}
    
    
    
  }

  /**
   * @description called when a pin is clicked on map and displays circuit/emotion tour details on map
   * is called after getMapCircuitList service call
   * @param location object - name, description, guid...
   * @returns void
   */
  showLocationInfo(location: any) {
    if (location.viewType == EALL_TRACKS.CIRCUIT) {
      this.locationData = location;
      this.currentDate = new Date().toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      if (this.locationData) {
        if (this.locationData.area) {
          this.circuitRadius = this.locationData.area * 1000;
        } else {
          this.circuitRadius = polyline.decode(location.encodedPolyline);
        }
        if (this.locationData.layouts) {
          this.layoutstext =
            "Layouts" + " " + " (" + this.locationData.layouts.length + ")";
          this.locationData.layouts = this.locationData.layouts.sort(
            (current: any, next: any) => {
              return (
                +new Date(next.updatedDate) - +new Date(current.updatedDate)
              );
            }
          );
        }
      }
      this.updateSlides();
      this.mapHeight = "100%";
      this.isShowCircuitInfo = true;
      this.backToLocationsFromCircuitDetails = true;
      this.searchLocation = false;
      this.isShowEmotionTourInfo = false;
      this.isShowAddCircuitSearch = false;
      this.isShowCircuitGeofence = false;
      this.previousType = this.selectedType;
    } else if (location.viewType == EALL_TRACKS.EMOTION_TOUR) {
      this.locationsHttpService
        .getEmotionTourWithAnnotations(location.guid,'location_module')
        .subscribe({
          next: (data: any) => {
            let emotionTourdata = data;
            emotionTourdata = emotionTourdata.features.filter((item: any) => {
              if (item.properties.landmark_type.toLowerCase() === "route") {
                if (
                  item.geometry.coordinates[0][0] ===
                    item.geometry.coordinates[
                      item.geometry.coordinates.length - 1
                    ][0] &&
                  item.geometry.coordinates[0][1] ===
                    item.geometry.coordinates[
                      item.geometry.coordinates.length - 1
                    ][1]
                ) {
                  data.isRoundTrip = true;
                }
                return item;
              }
            });
            this.emotionTourDetails = emotionTourdata[0];
            this.emotionTourDetails.guid = location.guid;
            this.emotionTourTrackencodedGpsPoints = data;
            this.isShowEmotionTourInfo = true;
            this.searchLocation = false;
            this.isShowCircuitInfo = false;
            this.isShowAddCircuitSearch = false;
            this.isShowCircuitGeofence = false;
            this.previousType = this.selectedType;
          },
        });
    }
  }

  /**
   * @description Called when Back To Locations is called from Cicuit Details page
   * @param search object
   */
  showSearch(search: any) {
    this.notificationMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.BROWSE_LOCATIONS_MSG;

    this.showMapView = search.showSearch;
    this.showSearchAndCreate = true;
    this.selectedType = this.previousType;
    if (search.deletedCircuitGuid) {
      this.selectedType = EALL_TRACKS.ALL;
      this.previousType = EALL_TRACKS.ALL;
      this.copyCicuitInfo = [];
      this.copyEmotionTour = [];
      this.copyDeletedCircuit = search.deletedCircuitGuid;
      this.getMapData(
        this.limitForEmotionTours,
        this.limitForCircuits,
        this.limitForCircuits,
        this.currentCoordinates,
        this.mapradius
      );
    } else if (this.route.snapshot.routeConfig?.path == "circuit/:guid") {
      this.selectedType = this.previousType = EALL_TRACKS.ALL
      this.getMapData(
        this.limitForEmotionTours,
        this.limitForCircuits,
        this.limitForCircuits,
        this.currentCoordinates,
        this.mapradius
      );

      this.selectedType = this.previousType;
      this.isShowCircuitInfo = false;
      this.backToLocationsFromCircuitDetails = true;
      this.isShowEmotionTourInfo = false;
      this.searchLocation = false;
      this.isShowTrackInfo = false;
      this.isShowAddCircuitSearch = false;
      this.isCircuitDetailsPage = true;
    } else {
      this.isShowCircuitInfo = false;
      this.isShowEmotionTourInfo = false;
      this.searchLocation = false;
      this.isShowTrackInfo = false;
      this.isShowAddCircuitSearch = false;
    }
    this.location.replaceState(`/${this.language}/locations`);
  }

  updateSlides() {
    this.slides = this.locationData.layouts?.map((item: any) => {
      if (typeof item.encodedGpsPoints == "string") {
        item.encodedGpsPoints = polyline.decode(item.encodedGpsPoints);
      }
      item.trackLength = item.trackLength / 1000;
      item.circuitGuid = this.circuitGuid;
      item.updatedDate = new Date(item.updatedDate).toLocaleDateString(
        "de-DE",
        { year: "numeric", month: "2-digit", day: "2-digit" }
      );
      return item;
    });
  }
  addNewLayout(showModal: any) {
    if (showModal) {
      this.showModal = true;
    }
  }
  
  closeModal(event: any) {
    if (!event.showModal) {
      this.showModal = false;
    }
  }

  showTrackPolyline(trackdetail: any) {
    this.trackdetails = trackdetail.encodedGpsPoints;
    this.sectorPointsDetails = trackdetail.sectorPoints;
    this.trackLength = trackdetail.trackLength;
    this.selectedTrack = trackdetail.guid;
    this.isShowTrackInfo = true;
    trackdetail.circuitGuid = this.circuitGuid;
    this.locationsHttpService.getLatestTrackDetails(trackdetail);
  }
  containerClick() {
    if (!this.type) {
      this.isshowDropDown = !this.isshowDropDown;
    }
  }
  
  public addNewCircuit(): void {
    localStorage.removeItem("form-image");
    this.searchHeader = LOCATIONS_CONSTANTS.BUTTON_NAMES.CIRCUITS;
    this.searchDescription = "BP_LM_landingPage_tracksSearchText";
    this.searchPlaceholderText = "BP_LM_SEARCH_LBL";
    this.selectedType = EALL_TRACKS.CIRCUIT;
    this.notificationMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.ZOOM_GEO_FENCE_MSG;
    this.isShowAddCircuitSearch = true;
    this.location.replaceState(`/${this.language}/locations/circuit/create`);
    this.showMapView = false;
    this.showSearchAndCreate = false;
    this.previousType = this.selectedType;
    this.mapHeight = "calc(100vh - 9.6875em)";
    this.page=ESEARCH_PAGE_STRING.ADD_NEW_CIRCUIT;
  }

  public primaryButtonClick(): void {
    let coor = this.circuitService.getEditedCoordinates();
    let circuitObj;
    if (coor?.radius) {
      circuitObj = {
        radius: coor.radius,
        coordinates: coor.lat.toString() + "," + coor.lng,
        name: EALL_TRACKS.CIRCUIT,
        type: "TEMPORARY",
      };
    } else {
      circuitObj = {
        encodedPolyline: coor,
        name: EALL_TRACKS.CIRCUIT,
        type: "TEMPORARY",
      };
    }
    if (coor) {
      this.isAddCircuit = true;
      this.statusTitle = "BP_LM_SCANNING_GEOFENCE_MSG";
      this.statusMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.VALIDATING;
      this.statusSubMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.VALIDATION_IN_PROGRESS
      this.statusImage = "";
      this.locationsHttpService.createGeofenceValidation(circuitObj).subscribe({
        next: (data: any) => {
          if (data.body) {
            this.circuitGuid = data.body.guid;
            this.statusMessage = "BP_LM_circuitGeofenceCreate_validationSuccess";
            this.statusSubMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.NEXT_STEPS_MSG;
            this.statusImage = "assets/locations/icn_tick.png";
            setTimeout(() => {
              let prevData = {
                state: { isprevPagelocation: true, shareable: true },
              };
              this.router.navigateByUrl(
                `/${this.language}/locations/circuit/add`,
                prevData
              );
            }, AppConstants.MIN_TIME_TOASTER);
          }
        },
        error: (_error) => {
          this.statusMessage = LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.GEOFENCE_VALIDATION_ERROR;
          this.statusSubMessage =
            LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.TRACK_EXISTS_MSG;
          this.statusImage = "assets/locations/shape.svg";
          setTimeout(() => {
            this.isAddCircuit = false;
          }, 2000);
        },
      });
    }
  }
  secondaryButtonClick(_event: any) {
    this.alertTitle = this.phrasePipe.transform(LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.CONFIRM_CANCEL, this.translationData)
    this.alertMessage =  LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.EDIT_CIRCUIT_UNSAVED_CHANGES;
    this.alertCancelBtnTitle = AppConstants.COMMON_BUTTON_LABELS.NO;
    this.alertActionBtnTitle = AppConstants.COMMON_BUTTON_LABELS.YES;
    this.isModalOpen = true;
    this.actionType = "revert";
    this.type = EALL_TRACKS.CIRCUIT;
    this.isNavigateBack = true;
  }

  selectedLocation(event: any) {
    if (event.showNotification) {
      this.locations = event.selectedGeoPoints;
      this.selectedButton = "polygon";
      this.trackDetailsName = "SET GEO-FENCE";
      
      if (!event.isMoreZoom) {
        // Needs Update
        this.notificationMessage = "BP_LM_ZOOM_GEO_FENCE_MSG";
        this.isShowCircuitGeofence = false;
        this.isShowZoombutton = true;
      } else {
        this.isShowCircuitGeofence = true;
        this.isShowZoombutton = false;
      }
    } else if (event.isEnableGeofence) {
      this.isShowCircuitGeofence = true;
      this.isShowZoombutton = false;

      if (
        event.selectedOption === "" ||
        event.selectedOption === "polygon" ||
        !event.selectedOption ||
        event.selectedOption === "escape"
      ) {
        this.selectedButton = event.selectedOption;
        this.enableRadiusInputBox = false;
        this.notificationMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.START_CIRCUIT_CREATION;
      } else if (event.selectedOption === "circle") {
        this.optionToBePassed = event.selectedOption;
        this.notificationMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.SET_CENTER_CIRCLULAR;
      }

      this.trackDetailsName = "SET GEO-FENCE";
    } else if (event.encodedDetails || event.circleGeoCoordinates) {
      this.isDisablePrimaryButton = false;
      this.isShowZoombutton = false;
      if (event.showAlert) {
        if (this.optionToBePassed !== event.Option && this.optionToBePassed !== "page-canceled") {
          if (event.Option === "escape") {
            event.Option = "polygon";
          }
          this.newSelection = event.Option ? event.Option : "polygon";
          this.isModalOpen = true;
          this.actionType = 'show-alert';
          this.changeDetectorRef.detectChanges();
        }
      }
    } else if (event.showLabel) {
      if (event.Option) {
        this.optionToBePassed = event.Option;
        if (event.Option === "polygon") {
          this.enableRadiusInputBox = false;
          this.notificationMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.START_CIRCUIT_CREATION;
        }
        if (event.Option === "circle") {
          this.notificationMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.SET_CENTER_CIRCLULAR;
        }
      }
    } else if (event.selectedOption === "escape") {
      this.selectedButton = 'clear';
    } else if (!event.isMoreZoom && event.selectedGeoPoints) {
      // Needs Update
      this.notificationMessage = "BP_LM_ZOOM_GEO_FENCE_MSG";
      this.isShowCircuitGeofence = false;

      this.selectedButton = event.selectedOption;
      this.optionToBePassed = event.selectedOption;

      this.isShowZoombutton = true;
      this.locations = event.selectedGeoPoints;
    } else {
      this.notificationMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.ZOOM_GEO_FENCE_MSG;
      this.isShowCircuitGeofence = false;
      this.isShowZoombutton = false;
      this.selectedButton = event.selectedOption;
      this.optionToBePassed = event.selectedOption;
      this.trackDetailsName = "SELECT LOCATION";
    }
  }
  optionSelected(option: any) {
    this.alertTitle = LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.ALERT;
    this.alertMessage = LOCATIONS_CONSTANTS.HELPER_MSGS.RESET_GEOFENCE_MSG;
    this.alertCancelBtnTitle = AppConstants.COMMON_BUTTON_LABELS.CANCEL;
    this.alertActionBtnTitle = AppConstants.COMMON_BUTTON_LABELS.CONTINUE;
    if (option === "polygon" || option === "circle") {
      this.selectedButton = option;
    } else {
      this.isDisablePrimaryButton = true;
      option = option.toString() + this.randomNumberService.randomNumber();
      this.optionToBePassed = option;
      this.selectedButton = option;
    }
  }
  navigateToBack(type: any) {
    this.alertTitle = LOCATIONS_CONSTANTS.ALERT_POPUP_TITLE.ALERT;
    this.alertMessage = LOCATIONS_CONSTANTS.CONFIRMATION_POPUP_MSGS.EDIT_CIRCUIT_UNSAVED_CHANGES;
    this.alertCancelBtnTitle = AppConstants.COMMON_BUTTON_LABELS.NO;
    this.alertActionBtnTitle = AppConstants.COMMON_BUTTON_LABELS.YES;
    this.isModalOpen = true;
    this.actionType = "revert";
    this.type = type;
    this.isNavigateBack = true;
  }
  zoomIn() {
    this.setZoomInLocation = {
      locations: this.locations,
      count: this.zoomCount + 1,
    };
    this.isNavigateBack = false;
  }

  setCreateOption(event: any) {
    this.isDropdownOpen = true;
    switch (event) {
      case EALL_TRACKS.CIRCUIT:
        this.addNewCircuit();
        break;

        case EALL_TRACKS.EMOTION_TOUR:
          this.addNewDealerFlow();
          break;
          case EALL_TRACKS.OFF_ROAD:
            this.startOffroadTourCreation();
            break;
    }
  }

  public startOffroadTourCreation() {
    let prevData = {
      state: { showNotFound: true },
    };
    this.resetDataForCreatingTour();
    this.router.navigateByUrl(
      `/locations/offroad-trail/create`, prevData);
  }

  public resetDataForCreatingTour() {
    this._mediaCarousalService.CancelCurrentStep.next(ETOUR_CREATION_STEPS.SECOND);
    this._mediaCarousalService.highlightCount.next(0);
    this._mediaCarousalService.hideContentOnLoadersPage.next(false);
    this._mediaCarousalService.startLocationButtonClicked.next(false);
  }

  /**
   * @description Called when we click on Emotion tour from 
   */
  public addNewDealerFlow(): void {
    let prevData = {
      state: { showNotFound: true },
    };
    this._mediaCarousalService.CancelCurrentStep.next(ETOUR_CREATION_STEPS.SECOND);
    this._mediaCarousalService.highlightCount.next(0);
    this._mediaCarousalService.hideContentOnLoadersPage.next(false);
    this._mediaCarousalService.startLocationButtonClicked.next(false);
    this.router.navigateByUrl(
      `/locations/business-tour/create`, prevData);
  }


  /**
   * @description called when an option is selected from emotion tour/circuits tab
   * @param event: string - circuits, emotion tour
   * @returns void
   */
  meunOptionClicked(event: any) {
    this.currentCoordinates = this._storeManagerService.getCurrentMapDataFromStore()?.latLng;
    this.searchPlaceholderText = "BP_LM_SEARCH_LBL";
    this.selectedType = event;
    this.previousType = event;
    this.isDropdownOpen = false;
    this.passedList = [];
    this.passedList = [...this.mapCircuitsList, ...this.emotionTourList, ...this.offRoadTourList];
    switch (event) {
      case EALL_TRACKS.CIRCUIT:
        this.searchHeader = LOCATIONS_CONSTANTS.BUTTON_NAMES.CIRCUITS;
        this.searchDescription = "BP_LM_TRACK_SEARCH_TEXT";
        this.searchPlaceholderText = "BP_LM_SEARCH_LBL";
        this.getMapData(0, this.limitForCircuits, 0, this.currentCoordinates, this.mapradius);
        break;
      //  /* Commenting for now, likely to be implemented in future 
     
        case EALL_TRACKS.ALL:
        this.searchHeader = AppConstants.COMMON_TITLE_LABELS.LOCATION_TITLE;
        this.searchDescription = "BP_LM_SEARCH_TEXT";
        this.notificationMessage = "BP_LM_circuitGeofenceCreate_browseNotification";
        this.getMapData(this.limitForEmotionTours, this.limitForCircuits, this.limitForCircuits, this.currentCoordinates, this.mapradius);
        break;
      case EALL_TRACKS.EMOTION_TOUR:
        this.searchHeader = "BP_LM_landingPage_emotiontourHeading";
        this.searchDescription = "BP_LM_landingPage_emotionTourSearchText";
        this.searchPlaceholderText = "BP_LM_landingPage_emotionTourSearchPlaceholder";
        this.getMapData(this.limitForEmotionTours, 0, 0, this.currentCoordinates, this.mapradius);
        break;
        case EALL_TRACKS.OFF_ROAD:
          this.searchHeader = LOCATIONS_CONSTANTS.BUTTON_NAMES.OFFROAD;
          this.searchDescription = "BP_LM_landingPage_emotionTourSearchText";
          this.searchPlaceholderText = "BP_LM_landingPage_emotionTourSearchPlaceholder";
          this.notificationMessage = "BP_LM_TOUR_AROUND_WORLD.";
          this.getMapData(0, 0, this.limitForEmotionTours, this.currentCoordinates, this.mapradius);
          break;
      case "mycircuits":
        this.searchResults = this.yourTracks?.circuitInfoList;
        this.searchHeader = "BP_LM_landingPage_myTrackText";
          this.searchDescription =
          "BP_LM_landingPage_yourTracksMessage";
        break;

      case "myemotiontour":
        this.searchResults = this.yourTracks?.emotionTourList;
        this.searchHeader = "BP_LM_landingPage_myTrackText";
          this.searchDescription =
          "BP_LM_landingPage_yourTracksMessage";
        break;

      default:
        this.searchHeader = AppConstants.COMMON_TITLE_LABELS.LOCATION_TITLE;
        this.searchDescription =
          environment.environment === 'dev' ? 'BP_LM_landingPage_locationsSearchText' : "BP_LM_landingPage_tracksSearchText";
        this.searchPlaceholderText = "BP_LM_SEARCH_LBL";
        break;
    }
  }

  /**
   * @description For popup display
   * @param modalData 
   */
  public displayDiscardCloseModal(modalData: any): void {
    this.isModalOpen = modalData.show
    this.actionType = modalData.actionType;
    this.alertTitle = modalData.title;
    this.alertMessage = modalData.message;
    this.alertActionBtnTitle = modalData.primaryBtnTitle;
    this.alertCancelBtnTitle = modalData.secondaryBtnTitle
  }

  getCancelModalResponse(event: any) {
    this.isModalOpen = false;
    if (event.action) {
      this.isDisablePrimaryButton = true; 
      this.optionToBePassed = this.newSelection;
      if (event.actionType === "revert") {
        this.showSearchAndCreate = true;
        // Needs Update
        this.notificationMessage = "BP_LM_CIRCUITS_WORLD";
        this.showMapView = true;
        this.isShowAddCircuitSearch = false;
        this.isShowCircuitGeofence = false;
        this.isShowCircuitInfo = false;
        this.isShowEmotionTourInfo = false;
        this.searchLocation = false;
        this.isShowTrackInfo = false;
        this.mapHeight = "100%";
        this.optionToBePassed = "clear";
        if (this.type.toLowerCase() === "locations") {
          this.previousType = EALL_TRACKS.ALL;
          this.selectedType = this.previousType;
        } else {
          this.previousType = EALL_TRACKS.CIRCUIT;
          this.selectedType = this.previousType;
          this.searchHeader = "BP_LM_landingPage_circuitsHeading";
          this.searchPlaceholderText = "BP_LM_SEARCH_LBL";
        }
        this.isNavigateBack = false;
        this.selectedButton = "";
        this.optionToBePassed = "page-canceled";
        this.isShowZoombutton = false;
        this.clearDrawnItems = 'clearDrawnItems'+ this.randomNumberService.randomNumber();
        this.location.replaceState(`/${this.language}/locations`);
      } else if (event.actionType === "show-alert") {
        this.newSelection = this.newSelection + "change";
        this.selectedButton = this.newSelection;
      } else if (event.actionType === 'DELETE_TRACK') {
        this.trackDetailsComponent?.deleteTrackOnConfirm();
      } else if (event.actionType === 'DISCARD_STEP') {
        this.trackDetailsComponent?.changeCurrentStep('VIEW_TRACK_DETAILS');
        this._circuitListService.setSelectedTrackStatus = false;
      }else if ('HIDE_TRACK' == event.actionType) {
        this._circuitListService.showTrackDetails = { show: false, layout: <any>null }
      }
    } else {
      this.isDisablePrimaryButton = false;    
      this.selectedButton = this.optionToBePassed;   
    }   
  }

  disableSearchBar(_event:any){
    this.showSearchAndCreate = false;
  }

  /**
   * @description called when a blue pin is selected within an emotion tour
   * @param annotationsId as an Object - geometry, if, properties...
   * @returns void
  */
  selectedAnnotation(annotationsId:any){
    this.selectedAnnotationsId=annotationsId;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * @description called while creating a new circuit, when an option from search list is selected
   * @param event: Object - title, distance, address
   * @returns void
   */
  getSelectedLocationAddress(event: any) {
    this.selectedLocationAddress = event;
  }

  /**
   * @description displays an error message when radius entered in input box > 100
   * @param radius: number
   */
  sendRadiusValuetoMap(radius: any) {
    if (radius < 100 && radius > 0) {
      this.radiusValueFromTool = radius;
    } else {
      this.toastService.show(ToastType.Error, LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS.INVALID_VALUE_ENETERED, '1%', '40%');
      setTimeout(() => {
        this.toastService.hide();
      }, AppConstants.MIN_TIME_TOASTER);
    }

  }

  sendRadiusValuetoInput(radius: any) {
    this.radiusValue = radius;
  }

  /**
   * @param data: string - 'BP_LM_START_CIRCUIT_CREATION' for Polygon
   */
  updateNotification(data: any) {
    this.notificationMessage = data;
  }

  enableRadiusInput(value: any) {
    this.enableRadiusInputBox = value;
  }

  changeUserSelectedType(type:any) {
    if (this.selectedUserType === type && this.selectedType === type) {
      return;
    }
    this.searchResults = null;
    this.circuitList = null;
    this.emotionTourList = null;
    this.selectedUserType = type;    
    this.circuitList = []
    this.emotionTourList = [];
    this.passedList = [];
    
    
      this.selectedType = EALL_TRACKS.ALL;
      this.searchHeader = AppConstants.COMMON_TITLE_LABELS.LOCATION_TITLE;
      this.searchDescription = LOCATIONS_CONSTANTS.HELPER_MSGS.SEARCH_ALL_LOCATIONS_MSG;
      this.circuitList = this.mapCircuitsList
      this.emotionTourList = this.mapEmotionTourList;
      this.getMapData(this.limitForEmotionTours, this.limitForCircuits, this.limitForEmotionTours, this.currentCoordinates, this.mapradius);

    // }
  }

  private convertImageTypeToBase64(imageDataUrl: any, element: CircuitInfoListEntity | EmotionTourListEntity) {
    this.locationsHttpService.getBlobImage(imageDataUrl).subscribe({
      next: (blob: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          if (element.media && element.media.image && element.media.image[0]) {
            element.media.image[0].thumbnailUrl = reader.result?.toString();
          }
        };
      }
    });
  }

  /**
   * @description This method is called when we search anything from the search bar
   * @param event - search results
   */
  public setSearchResults(searchResults:any) {
    this.searchResults = searchResults;
  }

  backToHomeFromMap() {
    this.changeUserSelectedType(EALL_TRACKS.ALL);
  }

  ngOnDestroy() {
    this.subscribeType.unsubscribe();
    this.subscriptions.forEach(subs => subs.unsubscribe());  
    this.searchSubscriprion.unsubscribe();
    this.removeIndexedDb();
  }
}